body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #e71010;
}

.background {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align:center;
  margin:auto;
  padding:0;
  background-color: #000000;
}

.kaffekopp {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.overlayBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}



button {
  padding: 8px;
}

.app {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: #b3b3b3;
}

.kontaktInfo {
  width: 100px;
  height: 100px;
  background-color: white;
  border-radius: 8px;
  color: rgb(255, 255, 255);
 
  position: absolute; 
  left: 0; 
  right: 0; 
  top: 0;
  bottom: 0;
  margin-left: auto; 
  margin-right: auto; 
  margin-top: auto;
  margin-bottom: auto;
  width: 100px;
}

.salutiLogo {
  width: 300px;
  height: 200px;
  position: absolute; 
  left: 0; 
  right: 0; 
  top: 0;
  bottom: 100px;
  margin-left: auto; 
  margin-right: auto; 
  margin-top: auto;
  margin-bottom: auto;
}




.letsTalkBtn {
  position: absolute; 
  left: 0; 
  right: 0; 
  top: 50%;
  bottom: 0;
  margin-left: auto; 
  margin-right: auto; 
  margin-top: auto;
  margin-bottom: auto;
  width: 150px;
  height: 50px;

padding: 8px;
border:0.1em solid #FFFFFF;
border-radius: 10px;
color:#ffffff;
background-color: transparent;
text-align:center;
transition: all 0.4s;
}


.letsTalkBtn:hover{
  color:#000000;
  background-color:#FFFFFF;
}

